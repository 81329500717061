/** @jsx jsx */
import * as React from 'react';
import {css, jsx} from '@emotion/core';
import dateFormat from 'dateformat';
import numeral from 'numeral';
import {db} from '../config';

interface IFirestoreDateProps {
  toDate(): () => Date; 
}

interface ITripProps {
  cost: number;
  description: string;
  endDate: IFirestoreDateProps;
  name: string;
  startDate: IFirestoreDateProps;
}

const wrapperStyle = css`
  background-color: #f4f4f4;
  margin: 5px;
  padding: 16px;
  max-width: 630px;

  @media (min-width: 768px) {
    margin: 10px 0;
    padding: 20px 44px;
  }
`;
const nameStyle = css`
  font-size: 18px;
  font-weight: normal;
  line-height: 21px;
  margin: 0;
`;

const descriptionStyle = css`
  margin-top: 22px;
`;

const costStyle = css`
  flex-shrink: 0;
  line-height: 40px;
  text-align: center;
  margin: 0;
  height: 40px;
  width: 100px;
  background-color: #c1e3f6;
  font-size: 18px;
`;

const dateTimeStyle = css`
  margin-top: 4px;
  font-weight: 300;
`;

const colStyle = css`
  display: flex;
  flex-direction: column;
  height: 35px;
  margin-top: 6px;
`;

const rowStyle = css`
  display: flex;
  justify-content: space-between;
`;

const Trip = ({
  cost, 
  description, 
  endDate, 
  name, 
  startDate
}: ITripProps) => {
  return (
    <div css={wrapperStyle}>
      <div css={rowStyle}>
        <div css={colStyle}>
          {name && <h2 css={nameStyle}>{name}</h2>}
          <p css={dateTimeStyle}>
            {startDate && `${dateFormat(startDate.toDate(), 'm/d')}`}
            {endDate && ` - ${dateFormat(endDate.toDate(), 'm/d/yy')}`}
          </p>
        </div>
        {cost && <p css={costStyle}>${numeral(cost).format('0,0')}</p>}
      </div>
      {description && <p css={descriptionStyle}>{description}</p>}
    </div>
  );
};

export default () => {
  const [trips, setTrips] = React.useState([]);
  const fetchTrips = async () => {
    const tripsRef = await db
      .collection('trips')
      .where('startDate', '>', new Date())
      .get();
    const trips = [];

    if (tripsRef.empty) {
      return trips;
    }

    tripsRef.forEach(trip => trips.push(trip.data()));

    setTrips(trips);
  };

  fetchTrips();

  return (
    <React.Fragment>
      {trips.map((trip, idx) => <Trip key={trip.name} {...trip} />)}
    </React.Fragment>
  );
};
