/** @jsx jsx */
import * as React from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {css, jsx} from '@emotion/core';
import Nav from '~/components/nav';
import dateFormat from 'dateformat';
import {SetIsLoggedInType} from '~/components/router';
import firebase from '~/config';
import {db, signout} from '~/config';

import * as styles from '~/pages/list/styles.css';

const tableStyle = css`
  width: calc(100% - 3rem);
  margin: auto;
  border-collapse: collapse;
  max-width: 76.8rem;
`;

const theadStyle = css`
  font-size: 1.6rem;
  font-weight: bold;
`;

const rowStyle = css`
  height: 4rem;
`;

const bottomBorder = css`
  border-bottom: 1px solid #b5b5b5;
`;

const rightAlign = css`
  text-align: right;
`;

export default ({setIsLoggedIn}) => {
  const [items, setItems] = React.useState([]);
  const {params} = useRouteMatch('/:collection/list');
  const {collection} = params;
  const path = collection.slice(0, collection.length - 1);
  const history = useHistory();

  React.useEffect(() => {
    let isSubscribed = true;

    db.collection(collection)
      .get()
      .then(ref => {
        const items = ref.docs.map(item => {
          const {name, startDate} = item.data();

          return (
            <tr key={name} css={rowStyle}>
              <td css={bottomBorder}>{name}</td>
              <td css={bottomBorder}>
                {dateFormat(startDate.toDate(), 'm/d/yy')}
              </td>
              <td
                css={css`
                  ${rightAlign}
                  ${bottomBorder}
                `}>
                <a
                  className={styles.link}
                  onClick={() => {
                    history.push(`/${path}/edit/${item.id}`);
                  }}>
                  edit
                </a>
                <a
                  className={styles.link}
                  onClick={() => {
                    if (confirm('are you sure?')) {
                      console.log('deleting');
                      db.collection(collection)
                        .doc(item.id)
                        .delete();
                    }
                  }}>
                  delete
                </a>
              </td>
            </tr>
          );
        });

        setItems(items);
      });

    return () => (isSubscribed = false);
  }, [items]);

  return (
    <React.Fragment>
      <Nav
        links={[
          {title: 'add', onClick: () => history.push(`/${path}/add`)},
          {
            title: 'sign out',
            onClick: signout(() => {
              setIsLoggedIn(false);
            }),
          },
        ]}
        title={collection}
      />
      <table css={tableStyle}>
        <thead css={theadStyle}>
          <tr css={rowStyle}>
            <td css={bottomBorder}>name</td>
            <td css={bottomBorder}>start</td>
            <td
              css={css`
              ${rightAlign}${bottomBorder}
            `}>
              action
            </td>
          </tr>
        </thead>
        <tbody>{items}</tbody>
      </table>
    </React.Fragment>
  );
};
