/** @jsx jsx */
import * as React from 'react';
import {Redirect} from 'react-router-dom';
import {css, jsx} from '@emotion/core';

import firebase from '~/config';
import Button from '~/components/button';
import Input from '~/components/input';
import Notification from '~/components/notification';

import * as styles from '~/pages/login/styles.css';

const loginStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: calc(100% - 32px);
  margin-top: -65px;
  margin-left: 16px;
  margin-right: 16px;
  @media (min-width: 768px) {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  @media (min-width: 1180px) {
    width: 33%;
  }
`;

interface ILoginProps {
  isLoggedIn: boolean;
  location?: string;
}

export default ({isLoggedIn, location}: ILoginProps) => {
  const [password, setPassword] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(
    'Oops, something when wrong',
  );

  const login = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(username, password)
      .catch(({message}) => {
        setShowError(true);
        setErrorMessage(message);
        setTimeout(() => setShowError(false), 5000);
      });
  };

  document.addEventListener('keyup', ({keyCode}) => {
    if (keyCode === 13) {
      login();
    }
  });

  const redirectTo = {
    pathname: '/trips/list',
    state: {from: location},
  };

  return isLoggedIn ? (
    <Redirect to={redirectTo} />
  ) : (
    <React.Fragment>
      <Notification isError={true} show={showError}>
        {errorMessage}
      </Notification>
      <div className={styles.login}>
        <Input
          onChange={({target: {value}}) => setUsername(value)}
          placeholder="email"
          type="text"
          value={username}
        />
        <Input
          onChange={({target: {value}}) => setPassword(value)}
          placeholder="password"
          type="password"
          value={password}
        />
        <Button onClick={login} text="submit" />
      </div>
    </React.Fragment>
  );
};
