import * as React from 'react';
import DatePicker from 'react-datepicker';
import * as styles from '~/components/date-time-picker/styles.css';
import 'react-datepicker/dist/react-datepicker.css';

interface IDateTimePickerProps {
  onChange: (data: any) => void;
  selected: string;
}

interface IButtonProps {
  value?: any;
  onClick?: any;
}

const Button = ({value, onClick}: IButtonProps) => (
  <button className={styles.button} onClick={onClick}>
    {value}
  </button>
);

export default ({onChange, selected}: IDateTimePickerProps) => {
  return (
    <DatePicker
      calendarClassName={styles.calendar}
      customInput={<Button />}
      dayClassName={() => styles.day}
      onChange={onChange}
      selected={selected}
      wrapperClassName={styles.wrapper}
    />
  );
};
