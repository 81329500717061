/** @jsx jsx */
import * as React from 'react';
import {css, jsx} from '@emotion/core';
import Button from '~/components/button';
import DateTimePicker from '~/components/date-time-picker';
import Input from '~/components/input';
import Nav from '~/components/nav';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {db, signout} from '~/config';
import collections from '~/collections';
import * as styles from '~/pages/edit/styles.css';

export default ({setIsLoggedIn}) => {
  const {params} = useRouteMatch('/:collection/edit/:id');
  const {collection, id} = params;
  const history = useHistory();
  const inputConfigs = collections[collection];
  const initialData = inputConfigs.reduce((data, {fieldName}) => {
    data[fieldName] = '';
    return data;
  }, {});
  const [data, setData] = React.useState(initialData);

  const [inputs, setInputs] = React.useState([]);

  const updateItem = () => {
    db.collection(`${collection}s`)
      .doc(id)
      .update(data)
      .then(() => alert("item updated"));
  };

  React.useEffect(() => {
    let isSubscribed = true;

    db.collection(`${collection}s`)
      .doc(id)
      .get()
      .then(item => {
        const data = item.data();

        for (let key in data) {
          let item = data[key];

          if (item.toDate) {
            data[key] = item.toDate();
          }
        }
        setData(data);
      });
    return () => (isSubscribed = false);
  }, []);

  React.useEffect(() => {
    const newInputs = inputConfigs.map(({fieldName, inputType, label}) => {
      if (inputType === 'textarea') {
        return (
          <textarea
            key={fieldName}
            className={styles[inputType]}
            name={fieldName}
            rows={6}
            onChange={e => setData({...data, [fieldName]: e.target.value})}
            value={data[fieldName]}></textarea>
        );
      } else if (inputType === 'date') {
        const value = data[fieldName] ? data[fieldName] : new Date();
        return (
          <DateTimePicker
            key={fieldName}
            selected={value}
            onChange={date => setData({...data, [fieldName]: date})}
          />
        );
      } else {
        return (
          <Input
            key={fieldName}
            className={styles[inputType]}
            placeholder={label}
            type={inputType}
            value={data[fieldName]}
            onChange={e => setData({...data, [fieldName]: e.target.value})}
          />
        );
      }
    });

    setInputs(newInputs);
  }, [data]);

  return (
    <React.Fragment>
      <Nav
        links={[
          {title: 'back', onClick: () => history.goBack()},
          {
            title: 'sign out',
            onClick: signout(() => {
              setIsLoggedIn(false);
            }),
          },
        ]}
        title={collection}
      />
      <div className={styles.wrapper}>
        {inputs}
        <Button onClick={updateItem} text="submit" />
      </div>
    </React.Fragment>
  );
};
