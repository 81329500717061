/** @jsx jsx */
import * as React from 'react';
import {css, jsx} from '@emotion/core';

interface INotificationProps {
  children: string;
  isError?: boolean;
  show: boolean;
}

const wrapperStyle = `
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 65px;
  display: flex;
  justify-content: center;
  transition: opacity .25s ease-in-out;
  -moz-transition: opacity .25s ease-in-out;
  -webkit-transition: opacity .25s ease-in-out;
`;

const messageStyle = css`
  color: #fff;
  font-size: 18px;
`;

export default ({
  children: message,
  isError = false,
  show = false,
}: INotificationProps) => {
  const opacity = show ? 1 : 0;
  let color = '#17ae82';

  if (isError) {
    color = '#ff6872';
  }

  return (
    <div
      css={css`
        ${wrapperStyle}
        background-color: ${color};
        opacity: ${opacity};
      `}>
      <p css={messageStyle}>{message}</p>
    </div>
  );
};
