export default {
  trip: [
    {
      label: 'start date',
      fieldName: 'startDate',
      inputType: 'date',
    },
    {
      label: 'end date',
      fieldName: 'endDate',
      inputType: 'date',
    },
    {
      label: 'name',
      fieldName: 'name',
      inputType: 'text',
    },
    {
      label: 'description',
      fieldName: 'description',
      inputType: 'textarea',
    },
    {
      label: 'cost',
      fieldName: 'cost',
      inputType: 'number'
    }
  ],
  trips: [],
};
