import * as React from 'react';
import * as styles from '~/components/nav/styles.css';

interface INavProps {
  links?: {title: string; className?: string; onClick: () => any}[];
  title?: string;
  wrapperClass?: string;
  titleClass?: string;
  linkClass?: string;
}

const Links = ({links}) => {
  return links.map(({title, className, onClick}) => {
    className = className ? className : styles.link;
    return (
      <li key={title}>
        <a className={className} onClick={onClick}>
          {title}
        </a>
      </li>
    );
  });
};

export default ({
  links,
  title,
  wrapperClass = styles.nav,
  titleClass = styles.title,
  linkClass = styles.list,
}: INavProps) => {
  return (
    <nav className={wrapperClass}>
      <h1 className={titleClass}>{title}</h1>
      {links && (
        <ul className={linkClass}>
          <Links links={links} />
        </ul>
      )}
    </nav>
  );
};
