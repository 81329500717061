import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const environments = {
  development: {
    apiKey: 'AIzaSyBWLVJks04dtJC4upg3LexkkHJ_69omc_8',
    authDomain: 'star-development-d8583.firebaseapp.com',
    databaseURL: 'https://star-development-d8583.firebaseio.com',
    projectId: 'star-development-d8583',
    storageBucket: 'star-development-d8583.appspot.com',
    messagingSenderId: '243581130451',
    appId: '1:243581130451:web:d797196277659080286626',
  },
  production: {
    apiKey: 'AIzaSyCAE4gmTlPg8462KsWW0didIvZqXHURSKo',
    authDomain: 'star-259617.firebaseapp.com',
    databaseURL: 'https://star-259617.firebaseio.com',
    projectId: 'star-259617',
    storageBucket: 'star-259617.appspot.com',
    messagingSenderId: '840303832512',
    appId: '1:840303832512:web:53727ac8b51570e20aac25',
    measurementId: 'G-1BT62MCDYK',
  },
};

firebase.initializeApp(environments[process.env.FIREBASE_CONFIG]);

export const db = firebase.firestore();

export const signout = cb => {
  return function() {
    firebase
      .auth()
      .signOut()
      .then(cb, err => {
        console.error(err);
      });
  };
};

export default firebase;
