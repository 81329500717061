import * as React from 'react';
import * as styles from '~/components/input/styles.css';

interface IInputProps {
  className?: string;
  onChange: any;
  placeholder: string;
  type: string;
  value: string;
}

export default ({
  className,
  onChange,
  placeholder,
  type,
  value,
}: IInputProps) => {
  className = className ? className : styles.input;

  return (
    <div css={styles.wrapper}>
      <input
        className={className}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        value={value}
      />
    </div>
  );
};
