import * as React from 'react';
import * as styles from './styles.css';

interface IButtonProps {
  onClick: () => any;
  text: string;
}

const Button = ({onClick, text}: IButtonProps) => (
  <div className={styles.wrapper}>
    <button className={styles.button} onClick={onClick}>
      {text}
    </button>
  </div>
);

export default Button;
