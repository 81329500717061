import * as React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import firebase from '~/config';
import Home from '~/pages/home';
import Login from '~/pages/login';
import PrivateRoute from './private-route';
import Add from '~/pages/add';
import Edit from '~/pages/edit';
import List from '~/pages/list';

export type SetIsLoggedInType = (boolean) => void;

export default () => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  firebase.auth().onAuthStateChanged(user => {
    if (user) {
      setIsLoggedIn(true);
    }
  });

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login">
          <Login isLoggedIn={isLoggedIn} />
        </Route>
        <PrivateRoute
          component={List}
          isLoggedIn={isLoggedIn}
          path="/trips/list"
          setIsLoggedIn={setIsLoggedIn}
        />
        <PrivateRoute
          component={Add}
          isLoggedIn={isLoggedIn}
          path="/trip/add"
          setIsLoggedIn={setIsLoggedIn}
        />
        <PrivateRoute
          component={Edit}
          isLoggedIn={isLoggedIn}
          path="/trip/edit/:id"
          setIsLoggedIn={setIsLoggedIn}
        />
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};
