import * as React from 'react';
import {Redirect, Route} from 'react-router-dom';

export default ({component: Component, isLoggedIn, setIsLoggedIn, ...rest}) => {
  const renderComponent = props => {
    const redirectTo = {pathname: '/login', state: {from: props.location}};

    return isLoggedIn ? (
      <Component {...props} setIsLoggedIn={setIsLoggedIn} />
    ) : (
      <Redirect to={redirectTo} />
    );
  };

  return <Route {...rest} render={renderComponent} />;
};
