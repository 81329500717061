import * as React from 'react';
import Nav from '~/components/nav';
import Trips from '~/components/trips';
import * as styles from '~/pages/home/styles.css';

export default () => {
  return (
    <div className={styles.wrapper}>
      <Nav title="STAR Trips" titleClass={styles.title} wrapperClass={styles.nav} />
      <Trips />
    </div>
  );
};
